import { CommonModule } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, HostListener, inject, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import zoomSdk from '@zoom/appssdk'; // Import Zoom Apps SDK
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { firstValueFrom } from 'rxjs';
import type { Project } from '../model/project.model';
import { ProjectApiService } from '../network/project-api.service';
@Component({
  selector: 'fl-forelight-plugin',
  standalone: true,
  imports: [CommonModule, FormsModule, NgxSkeletonLoaderModule],
  templateUrl: './forelight-plugin.component.html',
  styleUrl: './forelight-plugin.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ForelightPluginComponent implements OnInit, OnDestroy {
  private projectService = inject(ProjectApiService);
  private cdr = inject(ChangeDetectorRef);

  projects: Project[] = [];
  selectedProject: Project | null = null;
  selectedMeetingId: string = '';
  zoomUserId: string = '';
  participants: {
    participantId: string;
  }[] = [];
  loading$ = this.projectService.loading$;

  // Error messages
  errorMessage: string = '';
  errorRecordMessage: string = '';

  // State flags
  showRecordButton = false;
  showChangeProjectButton = false;
  showUpdateProjectButton = false;
  showProjectList = false;
  isOpen = false;

  private meetingJoinedListener: (() => void) | null = null;

  constructor() {
    console.log('Constructor: Start');
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(event: Event) {
    const target = event.target as HTMLElement;
    if (!target.closest('.custom-select-container')) {
      this.isOpen = false;
    }
  }

  async ngOnInit() {
    try {
      await this.initializeZoomSdk();

      const { context } = await zoomSdk.getAppContext();

      if (context) {
        await this.decryptContext(context);
      }

      zoomSdk.onParticipantChange(async (participantChangeEvent) => {
        console.log('Participant change event:', participantChangeEvent);
        let participantJoined = false;
        participantChangeEvent.participants.forEach((participant) => {
          if (participant.status === 'join') {
            if (this.participants.find((p) => p.participantId === participant.participantUUID)) {
              return;
            }
            this.participants.push({ participantId: participant.participantUUID });
            participantJoined = true;
            console.log('Participant joined:', participant.participantUUID);
          }
        });

        if (participantJoined) {
          await this.updateNbOfParticipants(this.participants.length);
        }
      });

      zoomSdk.onCloudRecording(async (cloudRecordingEvent) => {
        console.log('Cloud recording event:', cloudRecordingEvent);
        if (cloudRecordingEvent.action === 'stopped') {
          this.showProjectList = true;
          this.showRecordButton = true;
          this.showUpdateProjectButton = false;
          this.showChangeProjectButton = false;
        }
      });
    } catch (error) {
      console.error('Failed to initialize', error);
    } finally {
      console.log('ngOnInit: Flushed Application Insights');
    }
  }

  ngOnDestroy() {
    if (this.meetingJoinedListener) {
      this.meetingJoinedListener();
    }
  }

  async initializeZoomSdk() {
    try {
      console.log('Initializing Zoom SDK...');
      const configResponse = await zoomSdk.config({
        version: '0.16.0',
        popoutSize: { width: 480, height: 360 },
        capabilities: [
          'sendAppInvitation',
          'onSendAppInvitation',
          'authorize',
          'onAuthorized',
          'promptAuthorize',
          'getUserContext',
          'cloudRecording',
          'getMeetingContext',
          'getRunningContext',
          'getMeetingParticipants',
          'onParticipantChange',
          'sendMessage',
          'showAppInvitationDialog',
          'openUrl',
          'getAppContext',
          'onMeeting',
          'onCloudRecording'
        ]
      });
      console.log('Zoom SDK initialized:', configResponse);
    } catch (error) {
      console.error('Failed to initialize Zoom SDK:', error);
    }
  }

  async decryptContext(context: string): Promise<void> {
    try {
      const contextZoom = await firstValueFrom(this.projectService.decryptContext(context));
      this.selectedMeetingId = contextZoom.mid;
      this.zoomUserId = contextZoom.uid;
      console.log('Decrypted context:', contextZoom);
      if (this.zoomUserId && this.selectedMeetingId) {
        await this.fetchProjects(this.zoomUserId);
      }
    } catch (error) {
      this.errorMessage = 'Please connect zoom in forelight';
      console.error('Error decoding token:', error);
    } finally {
      console.log('Flushed Application Insights after decrypting context');
    }
  }

  async fetchProjects(zoomUserId: string): Promise<void> {
    try {
      const projects = await firstValueFrom(this.projectService.getProjects(zoomUserId));
      this.projects = projects;
      console.log('Projects fetched:', projects);
      if (projects.length === 0) {
        this.errorMessage = "You don't have any projects. Make sure the project is already in forelight";
      }
      if (projects.length > 0) {
        this.showProjectList = true;
        this.showRecordButton = true;
      }
      this.cdr.detectChanges();
    } catch (error) {
      this.errorMessage = 'Failed to get projects. Please connect zoom in forelight again.';
      console.error('Failed to fetch projects', error);
    } finally {
      console.log('Flushed Application Insights after fetching projects');
    }
  }

  changeProject() {
    this.showChangeProjectButton = false;
    this.showUpdateProjectButton = true;
    this.showProjectList = true;
    this.showRecordButton = false;
  }

  async recordMeeting(): Promise<void> {
    try {
      if (this.selectedMeetingId && this.selectedProject?.id) {
        try {
          const participantsResponse = await zoomSdk.getMeetingParticipants();
          console.log('Participants response:', participantsResponse);
          this.participants = participantsResponse.participants.map((participant) => ({
            participantId: participant.participantUUID
          }));
        } catch (error) {
          console.error('Failed to get participants', error);
        }
        const response = await firstValueFrom(
          this.projectService.associateMeetingWithProject(
            this.selectedMeetingId,
            this.selectedProject.id,
            this.participants.length
          )
        );
        if (response.success === true) {
          try {
            const response = await zoomSdk.cloudRecording({ action: 'start' });
            if (response.message === 'Success') {
              this.showRecordButton = false;
              this.showChangeProjectButton = true;
              this.showProjectList = false;
            }
          } catch (error: any) {
            console.error('Failed to start cloud recording', error);
            this.errorRecordMessage = error;
          }
        }
      }
    } catch (error) {
      console.error('Failed to associate meeting with project', error);
    } finally {
      console.log('Flushed Application Insights');
    }
  }

  async updateProjectMeeting() {
    if (this.selectedMeetingId && this.selectedProject?.id) {
      try {
        const participantsResponse = await zoomSdk.getMeetingParticipants();
        console.log('Participants response:', participantsResponse);
        this.participants = participantsResponse.participants.map((participant) => ({
          participantId: participant.participantUUID
        }));
      } catch (error) {
        console.error('Failed to get participants', error);
      }
      const updateProjectMeeting = await firstValueFrom(
        this.projectService.updateProjectAssociateMeeting(
          this.selectedMeetingId,
          this.selectedProject.id,
          this.participants.length
        )
      );
      if (updateProjectMeeting.success === true) {
        this.showUpdateProjectButton = false;
        this.showProjectList = false;
        this.showRecordButton = false;
        this.showChangeProjectButton = true;
      }
    }
  }

  async updateNbOfParticipants(nbParticipants: number) {
    if (!this.selectedMeetingId || !this.selectedProject?.id) {
      return;
    }
    console.log('Updating number of participants:', nbParticipants);
    await firstValueFrom(
      this.projectService.updateNbOfParticipants(this.selectedMeetingId, this.selectedProject?.id, nbParticipants)
    );
  }

  async testZoomChat() {
    try {
      await zoomSdk.sendMessage({ text: 'Test message', channel: 'MEETING_CHAT' } as any);
      console.log('Test message sent successfully');
    } catch (error) {
      console.error('Failed to send test message:', error);
    }
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  selectProject(project: Project) {
    this.selectedProject = project;
    this.isOpen = false;
  }
}
