<div class="forelight-plugin-container">
  <h1>Save Meeting to Forelight</h1>
  @if (!zoomUserId && errorMessage) {
    <span class="error-message">{{ errorMessage }} </span>
  }
  @if ((loading$ | async) && zoomUserId) {
    <div class="forelight-plugin-loading">
      <ngx-skeleton-loader
        appearance="line"
        count="1"
        [theme]="{ height: '20px', width: '30%', 'border-radius': '5px' }" />
      <ngx-skeleton-loader
        appearance="line"
        count="1"
        [theme]="{ height: '30px', width: '100%', 'border-radius': '10px' }" />
      <div class="forelight-plugin-action-loading">
        <ngx-skeleton-loader
          appearance="line"
          count="1"
          [theme]="{ height: '40px', width: '150px', 'border-radius': '10px' }" />
        <ngx-skeleton-loader
          appearance="line"
          count="1"
          [theme]="{ height: '40px', width: '150px', 'border-radius': '10px' }" />
      </div>
    </div>
  }
  @if (!(loading$ | async) && zoomUserId) {
    @if (errorMessage) {
      <span class="error-message">{{ errorMessage }}</span>
    }
    @if (!errorMessage) {
      <p class="subtitle">Select a project to record your meeting and let Leo analyze it for you</p>
      @if (errorRecordMessage) {
        <span class="error-message">{{ errorRecordMessage }}</span>
      }
      @if (!showProjectList) {
        <div class="record-message">
          <span>
            When the interview is finished, your interview will be available in your project:
            <br />
            {{ selectedProject?.name }}
          </span>
          <button type="button" class="btn btn-secondary" (click)="changeProject()">Change another project</button>
        </div>
      } @else {
        <div class="custom-select-container">
          <span>Choose a project:</span>
          <div class="custom-select-display" (click)="toggleDropdown()" (keyup.enter)="toggleDropdown()">
            {{ selectedProject?.name || 'Select a project' }}
            <span class="arrow" [class.open]="isOpen"></span>
          </div>
          <ul class="custom-select-list" [class.open]="isOpen">
            @for (project of projects; track project.id) {
              <li
                [class.selected]="project === selectedProject"
                (click)="selectProject(project)"
                (keyup.enter)="selectProject(project)">
                {{ project.name }}
              </li>
            }
          </ul>
        </div>
        @if (showRecordButton) {
          <button type="button" class="btn btn-primary" (click)="recordMeeting()" [disabled]="!selectedProject?.id">
            Record meeting
          </button>
        }
        @if (showUpdateProjectButton) {
          <button
            type="button"
            class="btn btn-primary"
            (click)="updateProjectMeeting()"
            [disabled]="!selectedProject?.id">
            Update Project
          </button>
        }
      }
    }
  }
</div>
