import { HttpClient, HttpHeaders } from '@angular/common/http'; // Import HttpClient
import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, from, Observable, switchMap } from 'rxjs';
import { environment } from '../environments/environment';
import type { ContextZoom } from '../model/context-zoom.model';
import type { Project } from '../model/project.model'; // Import the Project model (we'll define it later)
import { AuthService } from '../services/auth.service'; // We'll implement AuthService for authentication

@Injectable({
  providedIn: 'root'
})
export class ProjectApiService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  loading$ = new BehaviorSubject<boolean>(false);

  decryptContext(context: string): Observable<ContextZoom> {
    return new Observable<ContextZoom>((observer) => {
      this.authService
        .authenticateWithBackend()
        .then(() => {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authService.getJwtToken()}`
          });

          this.http.post<ContextZoom>(`${this.apiUrl}/zoom/decrypt-context`, { context }, { headers }).subscribe({
            next: (response) => {
              observer.next(response);
              observer.complete();
            },
            error: (error) => observer.error(error)
          });
        })
        .catch((error) => observer.error(error));
    });
  }

  getProjects(zoomUserId: string): Observable<Project[]> {
    this.loading$.next(true);
    return from(this.authService.authenticateWithBackend()).pipe(
      switchMap(() => {
        console.log('Authenticated with backend');
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authService.getJwtToken()}`
        });
        return this.http.get<Project[]>(`${this.apiUrl}/zoom/${zoomUserId}/projects`, { headers });
      }),
      finalize(() => {
        this.loading$.next(false);
      })
    );
  }

  associateMeetingWithProject(
    meetingId: string,
    projectId: string,
    nbParticipants: number
  ): Observable<{ success: boolean; message: string }> {
    return new Observable<{ success: boolean; message: string }>((observer) => {
      this.authService
        .authenticateWithBackend()
        .then(() => {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authService.getJwtToken()}`
          });

          this.http
            .post(`${this.apiUrl}/zoom/associate-project/${projectId}`, { nbParticipants, meetingId }, { headers })
            .subscribe({
              next: (response: any) => {
                observer.next(response);
                observer.complete();
              },
              error: (error) => observer.error(error)
            });
        })
        .catch((error) => observer.error(error));
    });
  }

  updateNbOfParticipants(meetingId: string, projectId: string, nbParticipants: number): Observable<void> {
    return new Observable<void>((observer) => {
      this.authService.authenticateWithBackend().then(() => {
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authService.getJwtToken()}`
        });

        this.http
          .patch(`${this.apiUrl}/zoom/associate-project/${projectId}`, { nbParticipants, meetingId }, { headers })
          .subscribe({
            next: () => {
              observer.next();
              observer.complete();
            }
          });
      });
    });
  }

  updateProjectAssociateMeeting(
    meetingId: string,
    projectId: string,
    nbParticipants: number
  ): Observable<{ success: boolean; message: string }> {
    return new Observable<{ success: boolean; message: string }>((observer) => {
      this.authService.authenticateWithBackend().then(() => {
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authService.getJwtToken()}`
        });

        this.http
          .patch(`${this.apiUrl}/zoom/associate-project/${projectId}`, { nbParticipants, meetingId }, { headers })
          .subscribe({
            next: (response: any) => {
              observer.next(response);
              observer.complete();
            },
            error: (error) => observer.error(error)
          });
      });
    });
  }

  deleteAssociateMeetingWithProject(meetingId: string, projectId: string): Observable<void> {
    return new Observable<void>((observer) => {
      this.authService
        .authenticateWithBackend()
        .then(() => {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authService.getJwtToken()}`
          });

          this.http.delete(`${this.apiUrl}/zoom/${meetingId}/associate-project/${projectId}`, { headers }).subscribe({
            next: () => {
              observer.next();
              observer.complete();
            },
            error: (error) => observer.error(error)
          });
        })
        .catch((error) => observer.error(error));
    });
  }
}
