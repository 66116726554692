import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ForelightPluginComponent } from '../forelight-plugin/forelight-plugin.component';

@Component({
  standalone: true,
  imports: [RouterModule, ForelightPluginComponent, FormsModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'zoom-in-meeting-app';
}
